<template>
  <Go
    v-if="!hasHistory"
    id="detail-back-btn"
    :to="linkToParent"
    class="back-btn parent"
  >
    <Times />
  </Go>
  <a
    v-else
    id="detail-back-btn"
    class="back-btn link"
    href="#"
    @click.prevent="back()"
  >
    <Times />
  </a>
</template>

<script>
import Times from '@/assets/images/times.svg';

export default {
  components: {
    Times,
  },
  props: {
    link: String,
    slug: String,
  },
  data() {
    return {
      hasHistory: false,
    };
  },
  computed: {
    linkToParent() {
      return this.link.replace(this.slug, '');
    },
  },
  async mounted() {
    this.hasHistory = await this.$store.dispatch('router/hasHistory');
  },
  methods: {
    back() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: var(--color-black);
  margin: 0;
  z-index: 101;
  @screen md {
    width: 60px;
    height: 60px;
  }
  svg {
    position: relative;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    color: var(--color-white);
    @screen md {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
