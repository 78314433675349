<template>
  <section class="row-24 foto-section bg-white m-0">
    <div class="name-container col-22 offset-1 md:col-4 md:offset-1">
      <h2>{{ fotografIn.title }}</h2>
    </div>
    <div class="elements-container col-22 offset-1 md:col-17 md:offset-1 p-0">

      <div ref="grid" class="grid">
        <div class="grid-sizer" />
        <!-- <div class="description-container grid-item">
          <p
            v-html="fotografIn.beschreibung"
          />
        </div> -->
        <div
          v-for="item in randomizedElements"
          :key="item.id"
          class="single-element-container grid-item"
        >
          <p v-if="item.text !== ''" class="element-text" v-html="item.text" />
          <img
            v-if="Object.keys(item.bild).length"
            class="element-image"
            :src="item.bild.large"
            :alt="item.bild.alt"
          >
        </div>
      </div>

    </div>
    <BackButton id="detail-back-btn" :link="fotografIn.link" :slug="fotografIn.slug" />
  </section>
</template>

<script>
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';

import BackButton from '../parts/BackButton.vue';

export default {
  components: {
    BackButton,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      fotografIn: this.payload,
      elements: this.payload.inhaltselemente,
    };
  },
  computed: {
    randomizedElements() {
      const copiedElements = [...this.elements];
      return this.shuffleElements(copiedElements);
    },
  },
  mounted() {
    imagesLoaded(this.$refs.grid, () => {
      this.iso = new Isotope(this.$refs.grid, {
        percentPosition: true,
        masonry: {
          columnWidth: '.grid-sizer',
          gutter: 20,
        },
      });
    });
  },
  unmounted() {
    setTimeout(() => this.iso.destroy(), 500);
  },
  methods: {
    shuffleElements(array) {
      let currentIndex = array.length; let
        randomIndex;
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.foto-section {
  padding-bottom: 4rem;
  .name-container {
    position: relative;
    text-transform: uppercase;
    padding: 2rem 0;
    @screen md {
      padding: 0;
      margin-top: 4rem;
    }
    h2 {
      font-size: 2rem;
      margin: 0;
      // text-align: center;
      @screen md {
        position: sticky;
        top: 3rem;
        text-align: left;
        // font-size: 2rem;
      }
      @screen lg {
        font-size: 3rem;
      }
    }
  }

  .elements-container {
    margin-top: 0;
    @screen md {
      margin-top: 4.25rem;
    }
  }

  .single-element-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;

    .element-image {
      margin: 0;
      width: 100%;
      height: 100%;
      max-height: 600px;
      object-fit: contain;
      object-position: center;
    }
    .element-text {
      margin: 0;
      :deep(p) {
        margin: 0;
      }
    }
  }

  .description-container {
    // padding: 1rem;
    &:deep(p) {
      margin: 0;
    }
  }
}

$spacing: 20px;

.grid {
  --cols: 1;
  @screen md {
    --cols: 2;
  }
}

.grid-sizer,
.grid-item {
  width: calc((100% / var(--cols)) - (#{$spacing} / (var(--cols)) * (var(--cols) - 1)));
}

.grid-item {
  margin-bottom: $spacing;
}

</style>
